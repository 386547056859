@font-face {
    font-family: 'QuebecSerialLightRegular';
    src: url('quebec_serial-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

*, ::after, ::before {
	box-sizing: border-box;
}

footer, header, nav, section {
	display: block;
}

html {
    -webkit-text-size-adjust: 100%;
}

body {
    font: normal 400 1rem/1.25 QuebecSerialLightRegular,sans-serif;
    letter-spacing: .05em;
    color: #282828;
    background-color: #fbfeff;
    margin: 3.5625em 0 0 0;
}

.alc {
    text-align: center;
}

#head {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: rgba(20,20,20,.95);
    box-shadow: 0 10px 20px rgba(0,0,0,.2);
    z-index: 1;
}

#logo {
    display: block;
    max-width: 778px;
    margin: 0 auto;
    text-align: left;
    color: #fff;
    font-style: italic;
    text-decoration: none;
    font-size: 1em;
    height: 3.5625em;
    background: no-repeat center left / contain;
    padding: 1.25em;
    letter-spacing: 0.015em;
    line-height: 1em;
}

.grad {
	background: #6bc7ea;
	background: linear-gradient(to right,#6bc7ea 0,#e94e91 20%,#ffd54e 40%,#63539e 60%,#78b72a 80%,#6bc7ea 100%);
	background-size: 250% 100%;
	height: 2px;
    animation: a7 30s linear infinite;
    width: 100%;
}

.card {
    padding: 60px 30px;
    max-width: 800px;
    margin: 0 auto;
    transition: opacity 0.5s;
}

.card.hide {
    opacity: 0;
}

.hero {
    height: 46.5vh;
    background: no-repeat center center / contain;
    opacity: 0;
    transform: translate(-200px,0);
    margin-bottom: 30px;
}

.hero.on {
    animation: a1 1s cubic-bezier(0.18, 0.89, 0.46, 1.07) forwards;
    opacity: 1;
    transform: none;
}

h1 {
    font-weight: normal;
    color: #22a0db;
    font-size: 2em;
    line-height: 1.42em;
    letter-spacing: 0.025em;
    margin: 0 0 1em;    
}

.heading-1 {
    font-weight: normal;
    font-size: 1.5em;
    line-height: 1.42em;
    letter-spacing: 0.038em;
    margin: 0 0 1em;
}

.overline {
    color: rgba(251,254,255,0.6);
    line-height: 1.2857em;
    font-size: 0.875em;    
    letter-spacing: 0.188em;
    font-weight: normal;
    margin: 0 0 0.71424em;
}

p, ul { margin: 1.5em 0; }

.lrg {
    font-size: 1.25em;
    line-height: 1.7em;
}

p:last-child {
    margin-bottom: 0;
}

.btn,
.alert-btn {
    text-transform: uppercase;
    font-size: 0.875em;
    letter-spacing: 0.188em;
    color: #fbfeff;
    display: inline-block;
    border-radius: 4px;
    box-shadow: none;
    padding: 0.64286em 1.14286em;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    transition: all .15s ease-in-out;
    outline: none;
    border: none;
    text-decoration: none;
}

.pri {
    background: #22a0db;
}

.pri:hover {
    background: #6bc7ea;
}

.pri:active {
    background: #0062cc;
}

.wbg.btn-busy,
.btn-busy:hover {
    background: rgba(251,254,255,0.2);
    position: relative;
    pointer-events: none;
}
.btn-busy::after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    border: 3px solid rgba(251,254,255,0.3);
    margin: -10px;
    border-radius: 50%;
    border-bottom-color: rgb(255, 255, 255);
    animation: spin 0.5s linear infinite;
}

#sec-1 {
    background: #2da0da;
    color: #fff;
}
#sec-2 {
    background: #d71957;
    color: #fff;
}
#sec-3 {
    background: #e69700;
    color: #fff;
}
#sec-4 {
    background: #30276a;
    color: #fff;
}
#sec-5 {
    background: #368033;
    color: #fff;
}
#sec-6 {
    background: #2ba68b;
    color: #fff;
}
#sec-7 {
    background: #d64545;
    color: #fff;
}

.sec-img {
    height: 35vh;
    position: relative;
    margin-bottom: 50px;
}

.sec-img-bg, .sec-img-fg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: no-repeat center center / contain;
}

.sec-img-bg {
    transition: transform 1s 300ms cubic-bezier(0.18, 0.89, 0.32, 1.28);
}

.sec-img-fg {
    transition: transform 1s 100ms cubic-bezier(0.18, 0.89, 0.32, 1.28);
}

.sec-img .out {
    transform: scale(0);
}

ul {
    padding-left: 44px;
    list-style-type: none;
    text-align: left;
    margin-bottom: 3em;
}

li {
    margin-bottom: 8px;
    position: relative;
}

li::before {
    content: "";
    position: absolute;
    top: 8px;
    left: -24px;
    width: 4px;
    height: 4px;
    background: #fbfeff;
    border-radius: 2px;
}

.dfx {
    display: flex;
}

.flx {
    flex: 1;
    margin: 0 30px;
}

.bc1 {
    color: #2da0da;
}
.bc2 {
    color: #d71957
}
.bc3 {
    color: #e69700;
}
.bc4 {
    color: #30276a;
}
.bc5 {
    color: #368033;
}
.bc6 {
    color: #2ba68b;
}
.bc7 {
    color: #d64545;
}
.bc8 {
    color: #00609c;
    margin: 24px 0;
}

.wbg {
    background: #fbfeff;
}

.wbg:hover {
    background: #fff;
}

.wbg:active {
    background: #e6e9ea;
}

.sec {
    color: #fbfeff;
    background: rgba(251,254,255,0.2);
}

.sec:hover {
    background: rgba(251,254,255,0.4);
}

.sec:active {
    background: rgba(251,254,255,0.1);
}

form { 
    text-align: left;
}

.fg {
    margin: 24px 0;
}

.fg > small {
    font-size: 12px;
    display: inline-block;
    margin: 6px 20px;
}

.tin {
    display: block;
    border: none;
    background: rgba(251,254,255,0.2);
    width: 100%;
    color: #fbfeff;
    font: normal 400 1rem/1.25 QuebecSerialLightRegular,sans-serif;
    letter-spacing: .05em;
    padding: 10px 20px;
    border-radius: 20px;
    outline: none;
    resize: none;
}

.tin::placeholder {
    color: rgba(251,254,255,0.4);
}

.tin:focus {
    box-shadow: inset 0px 0px 6px 0px rgba(251,254,255, 0.5);
}

.lb {
    font-size: 13px;
    text-transform: uppercase;
    letter-spacing: 0.125em;
    margin: 6px 20px;
    display: block;
}

.rad { 
    display: inline-block; 
}

.rad label {
	cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    display: inline-block;
    position: relative;
    padding: 10px 0 10px 56px;
    margin-right: 32px;
}

.rad label::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background: rgba(251,254,255,0.2);
    transition: all 0.5s;
}

.rad input {
    display: none;
}

.rad input:checked + label::before {
    box-shadow: inset 0px 0px 6px 0px rgba(251, 254, 255, 0.5);
}

.rad input:checked + label::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 12px;
    height: 12px;
    margin: 14px;
    border-radius: 20px;
    background: #fbfeff;
    animation: a2 0.5s cubic-bezier(.22,.61,.36,1);
}

/* Alert */
.alert-scrim {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #fbfeff;
    z-index: 2;
    opacity: 0;
    transition: opacity 500ms 100ms;
}
.alert-scrim.in {
    opacity: 0.7;
}

.alert {
    position: fixed;
    width: 500px;
    max-width: 90%;
    box-sizing: border-box;
    background: #fbfeff;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 0%);
    border-radius: 12px;
    box-shadow: 0 5px 10px rgba(0,0,0,0.15);
    overflow: hidden;
    opacity: 0;
    transition: all 750ms;
}
.alert.in {
    opacity: 1;
    transform: translate(-50%, -50%);
}
.alert-head {
    display: flex;
}
.alert-error {
    background: #d71957;
    color: #fff;
}
.alert-success {
    background: #78b72a;
    color: #fff;
}
.alert-icon {
    width: 64px;
    border: 4px solid #fff;
    border-radius: 50%;
    margin: 24px;
}
.alert-icon-path {
    fill: currentColor;
}
.alert-title {
    flex: 1;
    margin-bottom: 0;
    align-self: center;
    color: inherit;
    margin: 24px 24px 24px 0;
}
.alert-body {
    padding: 24px;
}
.alert-body p:first-child {
    margin-top: 0;
}
.alert-action {
    text-align: center;
    padding: 0 24px 24px 24px;
}
.alert-btn-primary {
    background: #00609c;
    border: 1px solid #00609c;
    color: #fbfeff;
    margin: 6px;
}
.alert-btn-secondary {
    background: #f0f7fa;
    color: #2da0da;
    border: 1px solid #2da0da;
    margin: 6px;
}
/*
background: #fbfeff;
color: #00609c;
margin: 24px 0;
*/
/* Animations */
@keyframes a1 {
    0% {
        opacity: 0;
        transform: translate(-200px, 0px);
    }
    100% {
        opacity: 1;
        transform: translate(0px,0px);
    }
}

@keyframes a7 {
    0% {
        background-position:0 0
    }
    100% {
        background-position:166.666% 0
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}